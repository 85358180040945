const prod = {
    api: {
        url: 'https://emg.getacentrum.cz/', // There has to be the trailing slash!
    }
};

const dev = {
    api: {
        url: 'https://localhost:5000/', // There has to be the trailing slash!
    }
};

export const jwtTokenField = '_jwt_token';

export const config = process.env.NODE_ENV === 'development' ? dev : prod;
