import React, { useState, useEffect } from 'react';
import {
    useToaster, FlexboxGrid, Panel, Table, Button, Modal, Form, DatePicker,
    Message, Grid, Row, Col, InputGroup, Input, TagPicker, Tag, TagGroup, SelectPicker
} from 'rsuite';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMagnifyingGlass, faTriangleExclamation } from '@fortawesome/free-solid-svg-icons'
import ColorHash from 'color-hash';
import { DateTime } from 'luxon';

import { jwtTokenField } from "../constants.js"
import { useLocalStorage } from "../hooks/useLocalStorage";
import { get, post, getFile } from '../utils/requests';

const { Column, HeaderCell, Cell } = Table;


var saveBlobAsFile = (function () {
    var a = document.createElement("a");
    document.body.appendChild(a);
    a.style = "display: none";
    return function (blob, fileName) {
        var url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = fileName;
        a.click();
        window.URL.revokeObjectURL(url);
    };
}());

export function LicenseListPage() {
    const [jwtToken, _setJwtToken] = useLocalStorage(jwtTokenField, null);

    const [licensesFetchNeeded, setLicensesFetchNeeded] = useState(true);
    const [licenses, setLicenses] = useState([]);

    const [availableLicenseTypesFetchNeeded, setAvailableLicenseTypesFetchNeeded] = useState(true);
    const [availableLicenseTypes, setAvailableLicenseTypes] = useState([]);

    const [availableModulesFetchNeeded, setAvailableModulesFetchNeeded] = useState(true);
    const [availableModules, setAvailableModules] = useState([]);

    const [searchQuery, setSearchQuery] = useState("");
    const [sortColumn, setSortColumn] = useState("id");
    const [sortType, setSortType] = useState("asc");

    const [showingEditModal, setShowEditModal] = useState(false);
    const [editError, setEditError] = useState("");
    const [editLicenseType, setEditLicenseType] = useState("");
    const [editLicenseId, setEditLicenseId] = useState("");
    const [editCompany, setEditCompany] = useState("");
    const [editCustomer, setEditCustomer] = useState("");
    const [editDeviceSerialNumber, setEditDeviceSerialNumber] = useState("");
    const [editExpiresAt, setEditExpiresAt] = useState(new Date());
    const [editActiveModules, setEditActiveModules] = useState("");

    const [showingAddModal, setShowAddModal] = useState(false);
    const [addLicenseType, setAddLicenseType] = useState("");
    const [addError, setAddError] = useState("");
    const [addCompany, setAddCompany] = useState("");
    const [addCustomer, setAddCustomer] = useState("");
    const [addDeviceSerialNumber, setAddDeviceSerialNumber] = useState("");
    const [addExpiresAt, setAddExpiresAt] = useState(new Date());
    const [addActiveModules, setAddActiveModules] = useState("");

    const [showingAreYouSureModal, setShowAreYouSureModal] = useState(false);
    const [toDeleteLicenseId, setToDeleteLicenseId] = useState("");

    const toaster = useToaster();
    let colorHash = new ColorHash();


    // Fetch license list
    useEffect(() => {
        if (licensesFetchNeeded) {
            get('api/license/list', jwtToken).then(data => {
                setLicenses(data.list);
            });
            setLicensesFetchNeeded(false);
        }
    }, [licensesFetchNeeded]);

    // Fetch available license types
    useEffect(() => {
        if (availableLicenseTypesFetchNeeded) {
            get('api/license/availableLicenseTypes', jwtToken).then(data => {
                setAvailableLicenseTypes(data.list);
            });
            setAvailableLicenseTypesFetchNeeded(false);
        }
    }, [availableLicenseTypesFetchNeeded, jwtToken]);

    // Fetch available software modules
    useEffect(() => {
        if (availableModulesFetchNeeded) {
            get('api/license/availableSoftwareModules', jwtToken).then(data => {
                setAvailableModules(data.list);
            });
            setAvailableModulesFetchNeeded(false);
        }
    }, [availableModulesFetchNeeded, jwtToken]);


    function openEditModal() {
        setShowEditModal(true);
    }

    function closeEditModal() {
        setShowEditModal(false);
        setEditError("");
        setEditLicenseType("");
        setEditLicenseId("");
        setEditCompany("");
        setEditCustomer("");
        setEditDeviceSerialNumber("");
        setEditExpiresAt(new Date());
        setEditActiveModules("");
    }

    function openAddModal() {
        setShowAddModal(true);
    }

    function closeAddModal() {
        setShowAddModal(false);
        setAddError("");
        setAddLicenseType("");
        setAddCompany("");
        setAddCustomer("");
        setAddDeviceSerialNumber("");
        setAddExpiresAt(new Date());
        setAddActiveModules("");
    }

    function openAreYouSureModal() {
        setShowAreYouSureModal(true);
    }

    function closeAreYouSureModal() {
        setShowAreYouSureModal(false);
        setToDeleteLicenseId("");
    }

    function getSortedAndFilteredLicenses() {
        // Filter by search query
        let filteredLicenses = licenses.filter(license => {
            return license.id.includes(searchQuery)
                || license.company.includes(searchQuery)
                || license.customer.includes(searchQuery)
                || license.deviceSerialNumber.includes(searchQuery);
        });

        // Sort
        let sortedFilteredLicenses = filteredLicenses.sort((a, b) => {
            let x = a[sortColumn];
            let y = b[sortColumn];

            const dateTimeX = DateTime.fromISO(x);
            const dateTimeY = DateTime.fromISO(y);
            if (dateTimeX.isValid && dateTimeY.isValid) {
                x = dateTimeX.toMillis();
                y = dateTimeY.toMillis();
            }

            if (typeof x === 'string') {
                x = x.charCodeAt(0);
            }
            if (typeof y === 'string') {
                y = y.charCodeAt(0);
            }

            if (sortType === 'asc') {
                return x - y;
            } else {
                return y - x;
            }
        });

        return sortedFilteredLicenses;
    }

    function handleSortColumn(sortColumn, sortType) {
        setSortColumn(sortColumn);
        setSortType(sortType);
    }

    const CustomSearchInput = ({ placeholder, ...props }) => (
        <InputGroup {...props}>
            <Input type="text" autoFocus={!showingAddModal && !showingEditModal} placeholder={placeholder} value={searchQuery} onChange={(v, e) => { setSearchQuery(v); }} />
            <InputGroup.Addon>
                <FontAwesomeIcon icon={faMagnifyingGlass} />
            </InputGroup.Addon>
        </InputGroup>
    );

    const DateCell = ({ rowData, dataKey, ...props }) => {
        let date = new Date(rowData[dataKey]);
        return (
            <Cell {...props} style={{ justifyContent: 'center', alignItems: 'center' }}>
                {date.toLocaleDateString("cs-CZ")}
            </Cell>
        )
    };

    const TagGroupCell = ({ rowData, dataKey, ...props }) => {
        const modules = rowData[dataKey].split(',');
        return (
            <Cell {...props} style={{ justifyContent: 'center', alignItems: 'center' }}>
                {modules.length > 0 && modules[0] !== "" ?
                    <TagGroup size="xs">
                        {modules.map(module => <Tag key={module} style={{ background: colorHash.hex(module), color: "#ffffff" }} >{module}</Tag>)}
                    </TagGroup>
                : null}
            </Cell>
        );
    };

    return (
        <>
            <FlexboxGrid justify="center" style={{ marginTop: 20 }}>
                <FlexboxGrid.Item colspan={20}>
                    <Panel header={<h3 align="center">Seznam aktivních licencí</h3>} shaded>

                        <FlexboxGrid justify="center" style={{ marginBottom: 20, marginTop: -10 }}>
                            <FlexboxGrid.Item colspan={6}>
                                <CustomSearchInput placeholder="Hledej zákazníka, uživatele, číslo přístroje nebo ID licence" />
                            </FlexboxGrid.Item>
                        </FlexboxGrid>

                        <Table
                            data={getSortedAndFilteredLicenses()}
                            sortColumn={sortColumn}
                            sortType={sortType}
                            onSortColumn={handleSortColumn}
                            autoHeight
                            bordered
                            cellBordered
                            wordWrap
                        >
                            <Column flexGrow={2} align="center" sortable>
                                <HeaderCell>ID licence</HeaderCell>
                                <Cell dataKey="id" />
                            </Column>

                            <Column flexGrow={1} align="center" sortable>
                                <HeaderCell>Typ licence</HeaderCell>
                                <Cell dataKey="type" />
                            </Column>

                            <Column flexGrow={1} align="center" sortable>
                                <HeaderCell>Zákazník</HeaderCell>
                                <Cell dataKey="company" />
                            </Column>

                            <Column flexGrow={1} align="center" sortable>
                                <HeaderCell>Uživatel</HeaderCell>
                                <Cell dataKey="customer" />
                            </Column>

                            <Column flexGrow={1} align="center" sortable>
                                <HeaderCell>Číslo přístroje</HeaderCell>
                                <Cell dataKey="deviceSerialNumber" />
                            </Column>

                            <Column flexGrow={1} align="center">
                                <HeaderCell>Aktivní moduly</HeaderCell>
                                <TagGroupCell dataKey="activeModules"/>
                            </Column>

                            <Column flexGrow={1} align="center">
                                <HeaderCell>Hardware hash</HeaderCell>
                                <Cell dataKey="machineId" />
                            </Column>

                            <Column flexGrow={1} align="center" sortable>
                                <HeaderCell>Expirace licence</HeaderCell>
                                <DateCell dataKey="expiresAt" />
                            </Column>

                            <Column flexGrow={1} align="center">
                                <HeaderCell>Soubor licence</HeaderCell>
                                <Cell>
                                    {rowData => {
                                        function downloadLicenseBundle() {
                                            const url = 'api/license/downloadBundle?licenseId=' + rowData.id;

                                            const message = (
                                                <Message showIcon type="info" duration={3000} closable>
                                                    Stahování začne za několik sekund...
                                                </Message>
                                            );
                                            toaster.push(message);

                                            getFile(url, jwtToken).then(data => {
                                                if (data.status == 200) {
                                                    const message = (
                                                        <Message showIcon type="success" duration={3000} closable>
                                                            Licence úspěsně stažena.
                                                        </Message>
                                                    );
                                                    toaster.push(message);

                                                    saveBlobAsFile(data.blob, "licence-" + rowData.id + ".emgl");
                                                } else {
                                                    const message = (
                                                        <Message showIcon type="error" duration={6000} closable>
                                                            Stažení licence se nezdařilo.
                                                        </Message>
                                                    );
                                                    toaster.push(message);
                                                }
                                            });
                                        }
                                        return (<a onClick={downloadLicenseBundle}>Stáhnout</a>);
                                    }}
                                </Cell>
                            </Column>

                            <Column flexGrow={1} align="center" fixed="right">
                                <HeaderCell>Akce</HeaderCell>
                                <Cell>
                                    {rowData => {
                                        function deleteLicense() {
                                            setToDeleteLicenseId(rowData.id);
                                            openAreYouSureModal();
                                        }
                                        function editLicense() {
                                            setEditLicenseId(rowData.id);
                                            setEditLicenseType(rowData.type)
                                            setEditCompany(rowData.company);
                                            setEditCustomer(rowData.customer);
                                            setEditDeviceSerialNumber(rowData.deviceSerialNumber);
                                            setEditExpiresAt(new Date(rowData.expiresAt));
                                            setEditActiveModules(rowData.activeModules);
                                            openEditModal();
                                        }
                                        return (
                                            <span>
                                                <a onClick={editLicense}> Editovat </a>{' '}|{' '}
                                                <a onClick={deleteLicense}> Odstranit </a>
                                            </span>
                                        );
                                    }}
                                </Cell>
                            </Column>
                        </Table>
                        
                        <Grid fluid style={{ marginTop: 20 }}>
                            <Row>
                                <Col xs={4} xsPush={20}><Button style={{ width: "150px" }} onClick={openAddModal} appearance="primary">Nová licence</Button></Col>
                            </Row>
                        </Grid>
                    </Panel>
                </FlexboxGrid.Item>
            </FlexboxGrid>



            <Modal open={showingEditModal} onClose={closeEditModal}>
                <Modal.Header>
                    <Modal.Title style={{ fontSize: "24px" }}>Detail licence</Modal.Title>
                    <h6>ID: {editLicenseId}</h6>
                </Modal.Header>
                <Modal.Body>
                    <Form fluid style={{ marginLeft: 10, marginRight: 10 }}>
                        <Form.Group>
                            <Form.ControlLabel>Typ licence</Form.ControlLabel>
                            <Form.Control block accepter={SelectPicker} searchable={false}
                                name="licenseType" placeholder="Vyberte typ licence"
                                data={availableLicenseTypes.map(f => { return { label: f, value: f }; })}
                                defaultValue={editLicenseType}
                                onChange={(value) => { setEditLicenseType(value); }}
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.ControlLabel>Zákazník</Form.ControlLabel>
                            <Form.Control name="company" value={editCompany} onChange={(value) => { setEditCompany(value); }}/>
                        </Form.Group>
                        <Form.Group>
                            <Form.ControlLabel>Uživatel</Form.ControlLabel>
                            <Form.Control name="customer" value={editCustomer} onChange={(value) => { setEditCustomer(value); }} />
                        </Form.Group>
                        <Form.Group>
                            <Form.ControlLabel>Číslo přístroje</Form.ControlLabel>
                            <Form.Control name="deviceSerialNumber" value={editDeviceSerialNumber} onChange={(value) => { setEditDeviceSerialNumber(value); }} />
                        </Form.Group>

                        <Form.Group>
                            <Form.ControlLabel>Aktivní moduly</Form.ControlLabel>
                            <TagPicker
                                block
                                placeholder="Vyberte moduly"
                                data={availableModules.map(f => { return { label: f, value: f }; })}
                                defaultValue={editActiveModules ? editActiveModules.split(",") : []}
                                onChange={(value) => { setEditActiveModules(value ? value.join(",") : ""); }}
                                renderValue={(values, items, tags) => {
                                    return values.map((tag, index) => (
                                        <Tag key={index} style={{ background: colorHash.hex(tag), color: "#ffffff" }}>
                                            {tag}
                                        </Tag>
                                    ));
                                }}
                            />
                        </Form.Group>

                        <Form.Group>
                            <Form.ControlLabel>Expirace licence</Form.ControlLabel>
                            <Form.Control block name="expiresAt" accepter={DatePicker} value={editExpiresAt} onChange={(value) => { setEditExpiresAt(value); }} />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                {editError ?
                    <div>
                        <Message showIcon type="error">{editError}</Message>
                        <br />
                    </div> : null}
                <Modal.Footer>
                    <Button onClick={() => {
                        post('api/license/update', {
                            licenseId: editLicenseId, company: editCompany, customer: editCustomer, deviceSerialNumber: editDeviceSerialNumber,
                            type: editLicenseType, expiresAt: editExpiresAt, activeModules: editActiveModules
                        }, jwtToken).then(data => {
                            setEditError("");

                            if (data.status == 200) {
                                const message = (
                                    <Message showIcon type="success" duration={3000} closable>
                                        Licence byla úspěšně modifikována!
                                    </Message>
                                );
                                toaster.push(message);

                                setLicensesFetchNeeded(true);
                                closeEditModal();
                            } else if (data.status == 400) {
                                setEditError("Neplatný typ licence, zákazník, uživatel, číslo přístroje nebo datum expirace");
                            } else {
                                const message = (
                                    <Message showIcon type="error" duration={6000} closable>
                                        Licenci se nepodařilo modifikovat!
                                    </Message>
                                );
                                toaster.push(message);
                            }
                        });
                    }} appearance="primary">Uložit</Button>
                    <Button onClick={closeEditModal} appearance="subtle">Zrušit</Button>
                </Modal.Footer>
            </Modal>



            <Modal open={showingAddModal} onClose={closeAddModal}>
                <Modal.Header>
                    <Modal.Title style={{ fontSize: "24px" }}>Nová licence</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form fluid style={{ marginLeft: 10, marginRight: 10 }}>
                        <Form.Group>
                            <Form.ControlLabel>Typ licence</Form.ControlLabel>
                            <Form.Control block accepter={SelectPicker} searchable={false}
                                name="licenseType" placeholder="Vyberte typ licence"
                                data={availableLicenseTypes.map(f => { return { label: f, value: f }; })}
                                onChange={(value) => { setAddLicenseType(value); }}
                            />
                        </Form.Group>

                        <Form.Group>
                            <Form.ControlLabel>Zákazník</Form.ControlLabel>
                            <Form.Control name="company" value={addCompany} onChange={(value) => { setAddCompany(value); }} />
                        </Form.Group>
                        <Form.Group>
                            <Form.ControlLabel>Uživatel</Form.ControlLabel>
                            <Form.Control name="customer" value={addCustomer} onChange={(value) => { setAddCustomer(value); }} />
                        </Form.Group>
                        <Form.Group>
                            <Form.ControlLabel>Číslo přístroje</Form.ControlLabel>
                            <Form.Control name="deviceSerialNumber" value={addDeviceSerialNumber} onChange={(value) => { setAddDeviceSerialNumber(value); }} />
                        </Form.Group>

                        <Form.Group>
                            <Form.ControlLabel>Aktivní moduly</Form.ControlLabel>
                            <TagPicker
                                block
                                placeholder="Vyberte moduly"
                                data={availableModules.map(f => { return { label: f, value: f }; })}
                                defaultValue={addActiveModules !== "" ? addActiveModules.split(",") : []}
                                onChange={(value) => { setAddActiveModules(value ? value.join(",") : ""); }}
                                renderValue={(values, items, tags) => {
                                    return values.map((tag, index) => (
                                        <Tag key={index} style={{ background: colorHash.hex(tag), color: "#ffffff" }}>
                                            {tag}
                                        </Tag>
                                    ));
                                }}
                            />
                        </Form.Group>

                        <Form.Group>
                            <Form.ControlLabel>Expirace licence</Form.ControlLabel>
                            <Form.Control block name="expiresAt" accepter={DatePicker} value={addExpiresAt} onChange={(value) => { setAddExpiresAt(value); }}/>
                        </Form.Group>
                    </Form>
                </Modal.Body>
                {addError ?
                    <div>
                        <Message showIcon type="error">{addError}</Message>
                        <br />
                    </div> : null}
                <Modal.Footer>
                    <Button onClick={() => {
                        post('api/license/create', {
                            company: addCompany, customer: addCustomer, deviceSerialNumber: addDeviceSerialNumber,
                            type: addLicenseType, expiresAt: addExpiresAt, activeModules: addActiveModules
                        }, jwtToken).then(data => {
                            setAddError("");

                            if (data.status == 200) {
                                const message = (
                                    <Message showIcon type="success" duration={3000} closable>
                                        Licence byla úspěšně vytvořena!
                                    </Message>
                                );
                                toaster.push(message);

                                setLicensesFetchNeeded(true);
                                closeAddModal();
                            } else if (data.status == 400) {
                                setAddError("Neplatný typ licence, zákazník, uživatel, číslo přístroje nebo datum expirace");
                            } else {
                                const message = (
                                    <Message showIcon type="error" duration={6000} closable>
                                        Licenci se nepodařilo vytvořit!
                                    </Message>
                                );
                                toaster.push(message);
                            }
                        });
                    }} appearance="primary">Vytvořit</Button>
                    <Button onClick={closeAddModal} appearance="subtle">Zrušit</Button>
                </Modal.Footer>
            </Modal>



            <Modal backdrop="static" open={showingAreYouSureModal} onClose={closeAreYouSureModal} size="xs">
                <Modal.Body>
                    <FontAwesomeIcon style={{ color: '#ffb300', fontSize: "24px" }} icon={faTriangleExclamation} />
                    &nbsp;&nbsp;Licence bude nevnávratně smazána. Chcete pokračovat?
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => {
                        post('api/license/delete', { licenseId: toDeleteLicenseId }, jwtToken).then(data => {
                            if (data.status == 200) {
                                const message = (
                                    <Message showIcon type="success" duration={3000} closable>
                                        License byla úspěšně smazána!
                                    </Message>
                                );
                                toaster.push(message);

                                setLicensesFetchNeeded(true);
                                closeAreYouSureModal();
                            } else {
                                const message = (
                                    <Message showIcon type="error" duration={6000} closable>
                                        Licenci se nepodařilo smazat!
                                    </Message>
                                );
                                toaster.push(message);

                                closeAreYouSureModal();
                            }
                        });
                    }} appearance="primary">Ok</Button>
                    <Button onClick={closeAreYouSureModal} appearance="subtle">Zrušit</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}
