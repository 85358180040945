import React, { useState, useEffect } from 'react';
import { useToaster, Message, FlexboxGrid, Panel, Table, Button, Modal, Tag, Input, InputGroup } from 'rsuite';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMagnifyingGlass, faTriangleExclamation } from '@fortawesome/free-solid-svg-icons'
import { DateTime } from 'luxon'

import { jwtTokenField } from "../constants.js"
import { useLocalStorage } from "../hooks/useLocalStorage";
import { get, post, getFile } from '../utils/requests';

const { Column, HeaderCell, Cell } = Table;


Date.prototype.yyyymmdd = function () {
    var mm = this.getMonth() + 1; // getMonth() is zero-based
    var dd = this.getDate();

    return [this.getFullYear(),
    (mm > 9 ? '' : '0') + mm,
    (dd > 9 ? '' : '0') + dd
    ].join('');
};

var saveBlobAsFile = (function () {
    var a = document.createElement("a");
    document.body.appendChild(a);
    a.style = "display: none";
    return function (blob, fileName) {
        var url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = fileName;
        a.click();
        window.URL.revokeObjectURL(url);
    };
}());

export function SoftwareListPage() {
    const [jwtToken, _setJwtToken] = useLocalStorage(jwtTokenField, null);

    const [softwareFetchNeeded, setSoftwareFetchNeeded] = useState(true);
    const [software, setSoftware] = useState([]);

    const [searchQuery, setSearchQuery] = useState("");
    const [sortColumn, setSortColumn] = useState("id");
    const [sortType, setSortType] = useState("asc");

    const [showingAreYouSureModal, setShowAreYouSureModal] = useState(false);
    const [toReleaseBuildTimestamp, setToReleaseBuildTimestamp] = useState("");
    const [toReleaseVersion, setToReleaseVersion] = useState("");
    const [toReleaseCommitHash, setToReleaseCommitHash] = useState("");

    const toaster = useToaster();

    // Fetch software list
    useEffect(() => {
        if (softwareFetchNeeded) {
            get('api/software/list', jwtToken).then(data => {
                setSoftware(data.list);
            });
            setSoftwareFetchNeeded(false);
        }
    }, [softwareFetchNeeded, jwtToken]);

    function openAreYouSureModal() {
        setShowAreYouSureModal(true);
    }

    function closeAreYouSureModal() {
        setShowAreYouSureModal(false);
        setToReleaseBuildTimestamp("");
        setToReleaseVersion("");
        setToReleaseCommitHash("");
    }

    function getFilteredAndSortedSoftware() {
        // Filter by search query
        let filteredSoftware = software.filter(software => {
            return software.version.includes(searchQuery) ||
                software.commitHash.includes(searchQuery);
        });

        // Sort
        let sortedFilteredSoftware = filteredSoftware.sort((a, b) => {
            let x = a[sortColumn];
            let y = b[sortColumn];

            const dateTimeX = DateTime.fromISO(x);
            const dateTimeY = DateTime.fromISO(y);
            if (dateTimeX.isValid && dateTimeY.isValid) {
                x = dateTimeX.toMillis();
                y = dateTimeY.toMillis();
            }

            if (typeof x === 'string') {
                x = x.charCodeAt(0);
            }
            if (typeof y === 'string') {
                y = y.charCodeAt(0);
            }

            if (sortType === 'asc') {
                return x - y;
            } else {
                return y - x;
            }
        });

        return sortedFilteredSoftware;
    }

    function handleSortColumn(sortColumn, sortType) {
        setSortColumn(sortColumn);
        setSortType(sortType);
    }

    const CustomSearchInput = ({ placeholder, ...props }) => (
        <InputGroup {...props}>
            <Input type="text" autoFocus={true} placeholder={placeholder} value={searchQuery} onChange={(v, e) => { setSearchQuery(v); }} />
            <InputGroup.Addon>
                <FontAwesomeIcon icon={faMagnifyingGlass} />
            </InputGroup.Addon>
        </InputGroup>
    );

    const CurrentVersionCell = ({ rowData, dataKey, ...props }) => (
        <Cell {...props}>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                {rowData[dataKey] ?
                    <Tag color="green">Aktuální</Tag> :
                    <Tag style={{ marginTop: "10px" }} color="blue"></Tag>
                }
            </div>
        </Cell>
    );

    const DateCell = ({ rowData, dataKey, ...props }) => {
        let date = new Date(rowData[dataKey]);
        return (
            <Cell {...props} style={{ justifyContent: 'center', alignItems: 'center' }}>
                {date.toLocaleDateString("cs-CZ")}
            </Cell>
        )
    };

    return (
        <>
            <FlexboxGrid justify="center" style={{ marginTop: 20 }}>
                <FlexboxGrid.Item colspan={12}>
                    <Panel header={<h3 align="center">Seznam dostupných verzí</h3>} shaded>

                        <FlexboxGrid justify="center" style={{ marginBottom: 20, marginTop: -10 }}>
                            <FlexboxGrid.Item colspan={8}>
                                <CustomSearchInput placeholder="Hledej verzi nebo commit hash" />
                            </FlexboxGrid.Item>
                        </FlexboxGrid>

                        <Table
                            data={getFilteredAndSortedSoftware()}
                            sortColumn={sortColumn}
                            sortType={sortType}
                            onSortColumn={handleSortColumn}
                            bordered
                            cellBordered
                            wordWrap
                            autoHeight
                        >
                            <Column flexGrow={1} align="center" sortable>
                                <HeaderCell>Verze</HeaderCell>
                                <Cell dataKey="version" />
                            </Column>

                            <Column flexGrow={1} align="center" sortable>
                                <HeaderCell>Commit hash</HeaderCell>
                                <Cell dataKey="commitHash" />
                            </Column>

                            <Column flexGrow={1} align="center" sortable>
                                <HeaderCell>Den sestavení</HeaderCell>
                                <DateCell dataKey="buildTimestamp" />
                            </Column>

                            <Column flexGrow={1} align="center" sortable>
                                <HeaderCell>Aktuálně vydaná verze</HeaderCell>
                                <CurrentVersionCell dataKey="isLatest" />
                            </Column>

                            <Column flexGrow={1} align="center">
                                <HeaderCell>Stažení verze</HeaderCell>
                                <Cell>
                                    {rowData => {
                                        function downloadSoftware() {
                                            const version = rowData.version;
                                            const commitHash = rowData.commitHash;
                                            const buildTimestamp = new Date(rowData.buildTimestamp).yyyymmdd();
                                            const url = 'api/software/download?buildTimestamp=' + buildTimestamp + '&version=' + version + '&commitHash=' + commitHash;

                                            const message = (
                                                <Message showIcon type="info" duration={3000} closable>
                                                    Stahování začne za několik sekund...
                                                </Message>
                                            );
                                            toaster.push(message);

                                            getFile(url, jwtToken).then(data => {
                                                if (data.status == 200) {
                                                    const message = (
                                                        <Message showIcon type="success" duration={3000} closable>
                                                            Úspěsně staženo
                                                        </Message>
                                                    );
                                                    toaster.push(message);
                                                    saveBlobAsFile(data.blob, "build_" + buildTimestamp + "_" + version + "_" + commitHash + ".zip");
                                                } else {
                                                    const message = (
                                                        <Message showIcon type="error" duration={6000} closable>
                                                            Není možné stáhnout software. Byla už zveřejněna nějaká verze?
                                                        </Message>
                                                    );
                                                    toaster.push(message);
                                                }
                                            });
                                        }
                                        return (<a onClick={downloadSoftware}>Stáhnout</a>);
                                    }}
                                </Cell>
                            </Column>

                            <Column flexGrow={1} align="center" fixed="right">
                                <HeaderCell>Vydání verze</HeaderCell>
                                <Cell>
                                    {rowData => {
                                        function publishSoftware() {
                                            setToReleaseBuildTimestamp(rowData.buildTimestamp);
                                            setToReleaseVersion(rowData.version);
                                            setToReleaseCommitHash(rowData.commitHash);
                                            openAreYouSureModal();
                                        }
                                        return (<a onClick={publishSoftware}>Vydat</a>);
                                    }}
                                </Cell>
                            </Column>
                        </Table>
                    </Panel>
                </FlexboxGrid.Item>
            </FlexboxGrid>

            <Modal backdrop="static" open={showingAreYouSureModal} onClose={closeAreYouSureModal} size="xs">
                <Modal.Body>
                    <FontAwesomeIcon style={{ color: '#ffb300', fontSize: "24px" }} icon={faTriangleExclamation} />
                    &nbsp;&nbsp;Verze bude zveřejněna jako update pro všechny zákazníky. Chcete pokračovat?
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => {
                        post('api/software/doRelease', { buildTimestamp: toReleaseBuildTimestamp, version: toReleaseVersion, commitHash: toReleaseCommitHash }, jwtToken).then(data => {
                            if (data.status == 200) {
                                const message = (
                                    <Message showIcon type="success" duration={3000} closable>
                                        Nová verze byla vydána!
                                    </Message>
                                );
                                toaster.push(message);

                                setSoftwareFetchNeeded(true);
                                closeAreYouSureModal();
                            } else {
                                const message = (
                                    <Message showIcon type="error" duration={6000} closable>
                                        Nebylo možné vydat novou verzi!
                                    </Message>
                                );
                                toaster.push(message);

                                closeAreYouSureModal();
                            }
                        });
                    }} appearance="primary">Ok</Button>
                    <Button onClick={closeAreYouSureModal} appearance="subtle">Zrušit</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}
