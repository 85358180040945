import * as React from "react";
import { Nav, Navbar } from "rsuite";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faListUl, faCodeFork, faMagnifyingGlassChart, faGear, faRightFromBracket } from '@fortawesome/free-solid-svg-icons'

import { jwtTokenField } from "../constants";
import { useLocalStorage } from "../hooks/useLocalStorage";

export function NavbarLoggedIn() {
    const [_jwtToken, setJwtToken] = useLocalStorage(jwtTokenField, null);

    return (
        <Navbar appearance="inverse">
            <Navbar.Brand href="/">
                <p>GETA EMG - ADMINISTRACE</p>
            </Navbar.Brand>

            <Nav>
                <Nav.Item icon={<FontAwesomeIcon style={{ paddingTop: "2px" }} icon={faListUl} />} onClick={() => {
                    window.location.href = "/licenses";
                }}>
                    Aktivní licence
                </Nav.Item>
                <Nav.Item icon={<FontAwesomeIcon style={{ paddingTop: "2px" }} icon={faCodeFork} />} onClick={() => {
                    window.location.href = "/software";
                }}>
                    Analyzér verze
                </Nav.Item>
                <Nav.Item icon={<FontAwesomeIcon style={{ paddingTop: "2px" }} icon={faMagnifyingGlassChart} />} onClick={() => {
                    window.location.href = "/logs";
                }}>
                    Analyzér log
                </Nav.Item>
            </Nav>
            <Nav pullRight>
                <Nav.Item icon={<FontAwesomeIcon style={{ paddingTop: "2px" }} icon={faRightFromBracket} />} onClick={() => {
                    setJwtToken("");
                    // Redirect to login page
                    window.location.href = "/login";
                }}>Odhlásit</Nav.Item>
            </Nav>
            <Nav pullRight>
                <Nav.Item icon={<FontAwesomeIcon style={{ paddingTop: "2px" }} icon={faGear} />} onClick={() => {
                    // Redirect to change password page
                    window.location.href = "/changePassword";
                }}>Změnit heslo</Nav.Item>
            </Nav>
        </Navbar>
    );
};
