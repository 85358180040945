import React, { useState } from 'react';
import { FlexboxGrid, Panel, Form, Button, ButtonToolbar, Message } from 'rsuite';

import { post } from "../utils/requests"
import { useLocalStorage } from "../hooks/useLocalStorage";
import { jwtTokenField } from '../constants';


export function LoginPage() {
    const [_jwtToken, setJwtToken] = useLocalStorage(jwtTokenField, null);

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [loginError, setLoginError] = useState("");
    const [loginInfo, setLoginInfo] = useState("");

    return (
        <>
            <br />
            <br />
            <FlexboxGrid justify="center" align="middle">
                <FlexboxGrid.Item colspan={12} md={6} style={{maxWidth: "400px"}}>
                    <Panel header={<h3>Přihlášení</h3>} shaded>
                        <Form fluid>
                            <Form.Group>
                                <Form.ControlLabel>Uživatelské jméno</Form.ControlLabel>
                                <Form.Control name="name" value={username} onChange={(value) => { setUsername(value); }} />
                            </Form.Group>
                            <Form.Group>
                                <Form.ControlLabel>Heslo</Form.ControlLabel>
                                <Form.Control name="password" type="password" value={password} onChange={(value) => { setPassword(value); }} />
                            </Form.Group>

                            {loginError ?
                                <div>
                                    <Message showIcon type="error">{loginError}</Message>
                                    <br />
                                </div> : null}

                            {loginInfo ?
                                <div>
                                    <Message showIcon type="info">{loginInfo}</Message>
                                    <br />
                                </div> : null}

                            <Form.Group>
                                <ButtonToolbar>
                                    <Button appearance="primary" onClick={() => {
                                        post('api/auth/login', { username: username, password: password }).then(data => {
                                            setLoginInfo("");
                                            setLoginError("");

                                            if (data.status == 200) {
                                                setLoginInfo("Uživatel přihlášen");
                                                setJwtToken(data.token);
                                                // Redirect to licenses page
                                                setTimeout(function () { window.location.href = "/licenses"; }, 2000);
                                            } else if (data.status == 400) {
                                                setLoginError("Chybějící uživatelské jméno nebo heslo");
                                            } else if (data.status == 403) {
                                                setLoginError("Nespravné uživatelské jméno nebo heslo");
                                            }
                                        });
                                    }}>Přihlásit</Button>
                                    <Button appearance="link" onClick={() => {
                                        // Redirect to licenses page
                                        window.location.href = "/forgotPassword";
                                    }}>Zapomenuté heslo?</Button>
                                </ButtonToolbar>
                            </Form.Group>
                        </Form>
                    </Panel>
                </FlexboxGrid.Item>
            </FlexboxGrid>
        </>
    );
}