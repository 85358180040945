import { config, jwtTokenField } from '../constants'

function invalidTokenRedirect() {
    window.localStorage.setItem(jwtTokenField, "");
    window.location.href = "/login";
}

export async function post(path = '', data = {}, token = '') {
    const response = await fetch(config.api.url + path, {
        method: 'POST', // GET, POST, PUT, DELETE, etc.
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
        redirect: 'follow',
        referrerPolicy: 'no-referrer',
        body: JSON.stringify(data) // Body data type must match "Content-Type" header
    });

    if (response.status == 401) {
        invalidTokenRedirect();
    }

    const json = await response.json(); // Parses JSON response into native JavaScript objects
    return {
        ...json,
        status: response.status,
        statusText: response.statusText
    }
}

export async function get(path = '', token = '') {
    const response = await fetch(config.api.url + path, {
        method: 'GET', // GET, POST, PUT, DELETE, etc.
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
        redirect: 'follow',
        referrerPolicy: 'no-referrer',
    });

    if (response.status == 401) {
        invalidTokenRedirect();
    }

    const json = await response.json(); // Parses JSON response into native JavaScript objects
    return {
        ...json,
        status: response.status,
        statusText: response.statusText
    }
}

export async function getFile(path = '', token = '') {
    const response = await fetch(config.api.url + path, {
        method: 'GET', // GET, POST, PUT, DELETE, etc.
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
    });

    if (response.status == 401) {
        invalidTokenRedirect();
    }

    const blob = await response.blob();
    return {
        blob: blob,
        status: response.status,
        statusText: response.statusText
    }
}

// Example usage
/*
post('https://example.com/answer', { answer: 42 })
    .then(data => {
        console.log(data); // JSON data parsed by `data.json()` call
    });
*/