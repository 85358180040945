import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import { App } from './App';
import registerServiceWorker from './utils/registerServiceWorker';

import 'rsuite/dist/rsuite.min.css'
//import 'rsuite/dist/styles/rsuite-default.css';

// Main entrypoint
const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const container = document.getElementById('root');
const root = createRoot(container)

root.render(
    <BrowserRouter basename={baseUrl}>
        <React.StrictMode>
            <App />
        </React.StrictMode>
    </BrowserRouter>
);

registerServiceWorker();
