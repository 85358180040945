import React, { useState } from 'react';
import { FlexboxGrid, Panel, Form, FormGroup, ControlLabel, FormControl, Button, ButtonToolbar, Message } from 'rsuite';

import { post } from "../utils/requests"


export function RegisterPage() {
    const [username, setUsername] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [passwordAgain, setPasswordAgain] = useState("");
    const [registrationKey, setRegistrationKey] = useState("");

    const [registrationError, setRegistrationError] = useState("");
    const [registrationInfo, setRegistrationInfo] = useState("");

    return (
        <>
            <br />
            <br />
            <FlexboxGrid justify="center" align="middle">
                <FlexboxGrid.Item colspan={12} md={6} style={{maxWidth: "400px"}}>
                    <Panel header={<h3>Registrace</h3>} shaded>
                        <Form fluid>
                            <Form.Group>
                                <Form.ControlLabel>Uživatelské jméno</Form.ControlLabel>
                                <Form.Control name="name" value={username} onChange={(value) => { setUsername(value); }} />
                            </Form.Group>
                            <Form.Group>
                                <Form.ControlLabel>Email</Form.ControlLabel>
                                <Form.Control name="email" value={email} onChange={(value) => { setEmail(value); }} />
                            </Form.Group>
                            <Form.Group>
                                <Form.ControlLabel>Heslo</Form.ControlLabel>
                                <Form.Control name="password" type="password" value={password} onChange={(value) => { setPassword(value); }} />
                            </Form.Group>
                            <Form.Group>
                                <Form.ControlLabel>Heslo znovu</Form.ControlLabel>
                                <Form.Control name="passwordAgain" type="password" value={passwordAgain} onChange={(value) => { setPasswordAgain(value); }} />
                            </Form.Group>
                            <Form.Group>
                                <Form.ControlLabel>Registrační klíč</Form.ControlLabel>
                                <Form.Control name="registrationKey" value={registrationKey} onChange={(value) => { setRegistrationKey(value); }} />
                            </Form.Group>

                            {registrationError ?
                                <div>
                                    <Message showIcon type="error">{registrationError}</Message>
                                    <br />
                                </div> : null}

                            {registrationInfo ?
                                <div>
                                    <Message showIcon type="info">{registrationInfo}</Message>
                                    <br />
                                </div> : null}

                            <Form.Group>
                                <ButtonToolbar>
                                    <Button appearance="primary" onClick={(event) => {
                                        if (password !== passwordAgain) {
                                            setRegistrationError("Hesla se neshoduji");
                                            return;
                                        }

                                        post('api/auth/register', { username: username, email: email, password: password, registrationKey: registrationKey }).then(data => {
                                            setRegistrationInfo("");
                                            setRegistrationError("");

                                            if (data.status == 200) {
                                                setRegistrationInfo("Registrace byla úspěšná");
                                                // Redirect to login page
                                                setTimeout(function () { window.location.href = "/login"; }, 2000);
                                            } else if (data.status == 403) {
                                                setRegistrationError("Nesprávný registrační klíč");
                                            } else if (data.status == 409) {
                                                setRegistrationError("Uživatel již existuje");
                                            } else if (data.status == 400) {
                                                setRegistrationError("Chybějící nebo neplatné údaje");
                                            }
                                        });
                                    }}>Registrovat</Button>
                                </ButtonToolbar>
                            </Form.Group>
                        </Form>
                    </Panel>
                </FlexboxGrid.Item>
            </FlexboxGrid>
        </>
    );
}