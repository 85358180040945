import * as React from "react"
import { Navbar, Nav } from "rsuite";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRightToBracket } from '@fortawesome/free-solid-svg-icons'

export function NavbarNotLoggedIn() {
    return (
        <Navbar appearance="inverse">
            <Navbar.Brand href="/">
                <p>GETA EMG - ADMINISTRACE</p>
            </Navbar.Brand>

            <Nav pullRight>
                <Nav.Item icon={<FontAwesomeIcon style={{ paddingTop: "2px" }} icon={faRightToBracket} />} onClick={() => {
                    // Redirect to login page
                    window.location.href = "/login";
                }}> Přihlásit </Nav.Item>
            </Nav>
        </Navbar>
    );
};