import React, { useState } from 'react';
import { FlexboxGrid, Panel, Form, Button, ButtonToolbar, Message } from 'rsuite';

import { jwtTokenField } from "../constants.js"
import { useLocalStorage } from "../hooks/useLocalStorage";
import { post } from "../utils/requests"


export function ChangePasswordPage() {
    const [jwtToken, _setJwtToken] = useLocalStorage(jwtTokenField, null);

    const [oldPassword, setOldPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");

    const [changePasswordError, setChangePasswordError] = useState("");
    const [changePasswordInfo, setChangePasswordInfo] = useState("");

    return (
        <>
            <br />
            <br />
            <FlexboxGrid justify="center" align="middle">
                <FlexboxGrid.Item colspan={12} md={6} style={{maxWidth: "400px"}}>
                    <Panel header={<h3>Změna hesla</h3>} shaded>
                        <Form fluid>
                            <Form.Group>
                                <Form.ControlLabel>Původní heslo</Form.ControlLabel>
                                <Form.Control name="oldPassword" type="password" value={oldPassword} onChange={(value) => { setOldPassword(value); }} />
                            </Form.Group>
                            <Form.Group>
                                <Form.ControlLabel>Nové heslo</Form.ControlLabel>
                                <Form.Control name="newPassword" type="password" value={newPassword} onChange={(value) => { setNewPassword(value); }} />
                            </Form.Group>


                            {changePasswordError ?
                            <div>
                                <Message showIcon type="error">{changePasswordError}</Message>
                                <br />
                                </div> : null}

                            {changePasswordInfo ?
                                <div>
                                    <Message showIcon type="info">{changePasswordInfo}</Message>
                                    <br />
                                </div> : null}

                            <Form.Group>
                                <ButtonToolbar>
                                    <Button appearance="primary" onClick={() => {
                                        post('api/auth/changePassword', { oldPassword: oldPassword, newPassword: newPassword }, jwtToken).then(data => {
                                            setChangePasswordInfo("");
                                            setChangePasswordError("");

                                            if (data.status == 200) {
                                                setChangePasswordInfo("Změna hesla byla úspěšná");
                                                // Redirect to licenses page
                                                setTimeout(function () { window.location.href = "/licenses"; }, 2000);
                                            } else if (data.status == 400) {
                                                setChangePasswordError("Původní heslo se neshoduje");
                                            } else {
                                                setChangePasswordError("Nepodařilo se změnit heslo");
                                            }
                                        });
                                    }}>Potvrdit</Button>
                                </ButtonToolbar>
                            </Form.Group>
                        </Form>
                    </Panel>
                </FlexboxGrid.Item>
            </FlexboxGrid>
        </>
    );
}