import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { Container, Content, Header } from 'rsuite';

import { jwtTokenField } from './constants';
import { NavbarNotLoggedIn }  from './components/NavbarNotLoggedIn';
import { NavbarLoggedIn } from './components/NavbarLoggedIn';
import { PageFooter }  from './components/PageFooter';
import { LoginPage }  from './pages/LoginPage';
import { LicenseListPage }  from './pages/LicenseListPage';
import { RegisterPage }  from './pages/RegisterPage';
import { ForgotPasswordPage }  from './pages/ForgotPasswordPage';
import { ChangePasswordPage }  from './pages/ChangePasswordPage';
import { LogListPage }  from './pages/LogListPage';
import { SoftwareListPage }  from './pages/SoftwareListPage';
import { HomePage } from './pages/HomePage';
import { useLocalStorage } from './hooks/useLocalStorage';


export function App() {
    const [token, _setToken] = useLocalStorage(jwtTokenField, null);

    let navbar;
    if (token) {
        navbar = <NavbarLoggedIn />;
    } else {
        navbar = <NavbarNotLoggedIn />;
    }

    // Render the app
    return (
        <Container style={{height: "100vh"}}>
            <Header>
                {navbar}
            </Header>
            <Content>
                <Switch>
                    <Route exact path="/">
                        <HomePage />
                    </Route>
                    <Route path="/login">
                        <LoginPage />
                    </Route>
                    <Route path="/register">
                        <RegisterPage />
                    </Route>
                    <Route path="/forgotPassword">
                        <ForgotPasswordPage />
                    </Route>
                    <Route path="/changePassword">
                        <ChangePasswordPage />
                    </Route>
                    <Route path="/licenses">
                        <LicenseListPage />
                    </Route>
                    <Route path="/logs">
                        <LogListPage />
                    </Route>
                    <Route path="/software">
                        <SoftwareListPage />
                    </Route>
                </Switch>
            </Content>
            <PageFooter />
        </Container>
    );
};