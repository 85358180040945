import React, { useState } from 'react';
import { FlexboxGrid, Panel, Form, Button, ButtonToolbar, Message } from 'rsuite';

import { post } from "../utils/requests"


export function ForgotPasswordPage() {
    const [username, setUsername] = useState("");
    const [email, setEmail] = useState("");
    const [resetError, setResetError] = useState("");
    const [resetInfo, setResetInfo] = useState("");

    return (
        <>
            <br />
            <br />
            <FlexboxGrid justify="center" align="middle">
                <FlexboxGrid.Item colspan={12} md={6} style={{maxWidth: "400px"}}>
                    <Panel header={<h3>Obnovení hesla</h3>} shaded>
                        <Form fluid>
                            <Form.Group>
                                <Form.ControlLabel>Uživatelské jméno</Form.ControlLabel>
                                <Form.Control name="name" value={username} onChange={(value) => { setUsername(value); }} />
                            </Form.Group>
                            <Form.Group>
                                <Form.ControlLabel>Email</Form.ControlLabel>
                                <Form.Control name="email" value={email} onChange={(value) => { setEmail(value); }} />
                            </Form.Group>

                            {resetError ?
                                <div>
                                    <Message showIcon type="error">{resetError}</Message>
                                    <br />
                                </div> : null}

                            {resetInfo ?
                                <div>
                                    <Message showIcon type="info">{resetInfo}</Message>
                                    <br />
                                </div> : null}

                            <Form.Group>
                                <ButtonToolbar>
                                    <Button appearance="primary" onClick={() => {
                                        post('api/auth/resetPassword', { username: username, email: email }).then(data => {
                                            setResetInfo("");
                                            setResetError("");

                                            if (data.status == 200) {
                                                setResetInfo("Nové heslo odesláno na email");
                                                // Redirect to login page
                                                setTimeout(function () { window.location.href = "/login"; }, 2000);
                                            } else if (data.status == 400) {
                                                setResetError("Chybějící uživatelské jméno nebo email");
                                            } else if (data.status == 404) {
                                                setResetError("Neexistující uživatel nebo neshodující se email");
                                            }
                                        });
                                    }}>Odeslat nové heslo</Button>
                                </ButtonToolbar>
                            </Form.Group>
                        </Form>
                    </Panel>
                </FlexboxGrid.Item>
            </FlexboxGrid>
        </>
    );
}