import React, { useState, useEffect } from 'react';
import { FlexboxGrid, Panel, Table, Tag, Input, InputGroup } from 'rsuite';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons'
import { DateTime } from 'luxon'

import { jwtTokenField } from '../constants';
import { useLocalStorage } from "../hooks/useLocalStorage";
import { get } from '../utils/requests'

const { Column, HeaderCell, Cell } = Table;


export function LogListPage() {
    const [jwtToken, _setJwtToken] = useLocalStorage(jwtTokenField, null);

    const [logsFetchNeeded, setLogsFetchNeeded] = useState(true);
    const [logs, setLogs] = useState([]);

    const [searchQuery, setSearchQuery] = useState("");
    const [sortColumn, setSortColumn] = useState("id");
    const [sortType, setSortType] = useState("asc");


    // Fetch log messages list
    useEffect(() => {
        if (logsFetchNeeded) {
            get('api/log/list', jwtToken).then(data => {
                setLogs(data.list);
            });
            setLogsFetchNeeded(false);
        }
    }, [logsFetchNeeded]);


    function getColorForLogLevel(level)
    {
        switch (level.toLowerCase()) {
            case "trace":
                return "violet";
                break;
            case "debug":
                return "orange";
                break;
            case "info":
                return "cyan";
                break;
            case "warn":
                return "yellow";
                break;
            case "error":
                return "red";
                break;
            default:
                return "blue";
        }
    }

    const LogLevelCell = ({ rowData, dataKey, ...props }) => (
        <Cell {...props} style={{ padding: 0 }}>
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '12px'}}>
                <Tag color={getColorForLogLevel(rowData[dataKey])}>{rowData[dataKey]}</Tag>
            </div>
        </Cell>
    );

    function getFilteredAndSortedLogs() {
        // Filter by search query
        let filteredLogs = logs.filter(log => {
            return log.version.includes(searchQuery) ||
                log.licenseId.includes(searchQuery);
        });

        // Sort
        let sortedFilteredLogs = filteredLogs.sort((a, b) => {
            let x = a[sortColumn];
            let y = b[sortColumn];

            const dateTimeX = DateTime.fromISO(x);
            const dateTimeY = DateTime.fromISO(y);
            if (dateTimeX.isValid && dateTimeY.isValid) {
                x = dateTimeX.toMillis();
                y = dateTimeY.toMillis();
            }

            if (typeof x === 'string') {
                x = x.charCodeAt(0);
            }
            if (typeof y === 'string') {
                y = y.charCodeAt(0);
            }

            if (sortType === 'asc') {
                return x - y;
            } else {
                return y - x;
            }
        });

        return sortedFilteredLogs;
    }

    function handleSortColumn(sortColumn, sortType) {
        setSortColumn(sortColumn);
        setSortType(sortType);
    }

    const CustomSearchInput = ({ placeholder, ...props }) => (
        <InputGroup {...props}>
            <Input type="text" autoFocus={true} placeholder={placeholder} value={searchQuery} onChange={(v, e) => { setSearchQuery(v); }} />
            <InputGroup.Addon>
                <FontAwesomeIcon icon={faMagnifyingGlass} />
            </InputGroup.Addon>
        </InputGroup>
    );

    const DateTimeCell = ({ rowData, dataKey, ...props }) => {
        let date = new Date(rowData[dataKey]);
        return (
            <Cell {...props} style={{ justifyContent: 'center', alignItems: 'center' }}>
                {date.toLocaleString("cs-CZ")}
            </Cell>
        )
    };

    return (
        <>
            <FlexboxGrid justify="center" style={{ marginTop: 20 }}>
                <FlexboxGrid.Item colspan={20}>
                    <Panel header={<h3 align="center">Prohlížeč logů</h3>} shaded>

                        <FlexboxGrid justify="center" style={{ marginBottom: 20, marginTop: -10 }}>
                            <FlexboxGrid.Item colspan={8}>
                                <CustomSearchInput placeholder="Hledej verzi nebo ID licence" />
                            </FlexboxGrid.Item>
                        </FlexboxGrid>

                        <Table
                            data={getFilteredAndSortedLogs()}
                            sortColumn={sortColumn}
                            sortType={sortType}
                            onSortColumn={handleSortColumn}
                            autoHeight
                            bordered
                            cellBordered
                            wordWrap
                        >
                            <Column flexGrow={2} align="center" sortable>
                                <HeaderCell>Datum a čas</HeaderCell>
                                <DateTimeCell dataKey="timestamp" />
                            </Column>

                            <Column flexGrow={1} align="center" sortable>
                                <HeaderCell>Level</HeaderCell>
                                <LogLevelCell dataKey="level" />
                            </Column>

                            <Column flexGrow={8} align="center" sortable>
                                <HeaderCell>Text</HeaderCell>
                                <Cell dataKey="message" />
                            </Column>

                            <Column flexGrow={2} align="center" sortable>
                                <HeaderCell>Verze software</HeaderCell>
                                <Cell dataKey="softwareVersion" />
                            </Column>

                            <Column flexGrow={3} align="center" sortable>
                                <HeaderCell>ID Licence</HeaderCell>
                                <Cell dataKey="licenseId" />
                            </Column>
                        </Table>
                    </Panel>
                </FlexboxGrid.Item>
            </FlexboxGrid>
        </>
    );
}
