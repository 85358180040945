import React from 'react';
import { FlexboxGrid, Panel } from 'rsuite';

export function HomePage() {
    return (
        <>
            <br />
            <br />
            <FlexboxGrid justify="center" align="middle">
                <FlexboxGrid.Item colspan={12} md={6} style={{maxWidth: "600px"}}>
                    <Panel header={<h3>Vítejte v GETA EMG administraci!</h3>} shaded>
                        Pokračujte výběrem jedné z možností v horním panelu.
                    </Panel>
                </FlexboxGrid.Item>
            </FlexboxGrid>
        </>
    );
}